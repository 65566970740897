<template>
  <div>
    <FullCalendar :options="calendarOptions" />

    <template v-if="this.$store.state.showMonoPopupConduite">
      <MonoModal
        visible="true"
        :moniteurs="this.moniteurs"
        @getLesMoniteurs="getLesMoniteurs"
        :monoChecked="moniteurchoix"
        v-on:moniteurChoicePlanning="moniteurChoicePlanning"
        :choixMonoSaves="choixMonoSaves"
      />
    </template>

    <Dialog
      v-model:visible="resaModal"
      modal
      header="Réservation"
      :style="{ width: '80rem' }"
    >
      <div class="head">
        <p>{{ resaHeader }}</p>
        <select class="monoSelectResaEleves" name="moniteurs" id="mono" :value="monoChoixResa">
          <template v-for="m in moniteurs" :key="m.id">
            <template v-if="m.idMono == monoChoixResa">
              <option :value="m.idMono" class="selectMono">
                {{ m.fullname }}
              </option>
            </template>
            <template v-else>
              <option :value="m.idMono">{{ m.fullname }}</option>
            </template>
          </template>
        </select>
        <p>Boite : {{ boiteResa }}</p>


        <label>
          Tous les élèves
          <input
            type="checkbox"
            name="allStudent"
            id="allStudent"
            @click="getAllEleves"
          />
        </label>
      </div>
      <div class="table">
        <template v-if="showEleveDispo">
          <DataTable
            :value="eleveDispos"
            tableStyle="width: 100%"
            paginator
            :rows="5"
            :rowsPerPageOptions="[5, 10, 20, 50]"
          >
            <Column field="code" header="">
              <template #body="{ data }">
                <input
                  type="radio"
                  name="eleve"
                  :id="data.id"
                  :value="data.id"
                  @click="getLieuEleve(data)"
                  v-model="idEleveChoixResa"
                />
              </template>
            </Column>
            <Column field="nom" header="Nom"></Column>
            <Column field="prenom" header="Prénom"></Column>
            <Column field="telephone" header="Tel"></Column>
            <Column field="dossier.adresse.ville" header="Ville"></Column>
            <Column field="solde" header="Solde"></Column>
            <Column field="category" header="Lieux Début"></Column>
            <Column field="boiteNom" header="Boite" sortable ></Column>
            <Column field="dispo" header="Dispo 2H"></Column>
          </DataTable>
        </template>
        <template v-else>
          <DataTable
            :value="eleves"
            tableStyle="width: 100%"
            paginator
            :rows="5"
            :rowsPerPageOptions="[5, 10, 20, 50]"
          >
            <Column field="code" header="">
              <template #body="{ data }">
                <input
                  type="radio"
                  name="eleve"
                  :id="data.id"
                  :value="data.id"
                  @click="getLieuEleve(data)"
                  v-model="idEleveChoixResa"
                />
              </template>
            </Column>
            <Column field="nom" header="Nom"></Column>
            <Column field="prenom" header="Prénom"></Column>
            <Column field="telephone" header="Tel"></Column>
            <Column field="dossier.adresse.ville" header="Ville"></Column>
            <Column field="solde" header="Solde"></Column>
            <Column field="category" header="Lieux Début"></Column>
            <Column field="boiteNom" header="Boite" sortable></Column>
            <Column field="quantity" header="Dispo 2H"></Column>
          </DataTable>
        </template>

      </div>
      <template v-if="idEleveChoixResa != null">
        <template v-if="this.eleveChoixResa.choixDispo != 'null'">

          <template v-if="this.eleveChoixResaMonoSelect == true">

            <select
              name="debLieu"
              id="debLieu"
              @click="getLieuFin"
              class="resaModalEleves"
              v-model="lieuDebChoix"
            >
              <option>Lieu de début</option>
              <template v-for="l in lieuxDeb" :key="l.id">
                <template v-if="l.choixEleve == true">
                  <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
                </template>
              </template>
            </select>

            <template v-if="lieuxFin != null">

              <select name="finLieu" id="finLieu" v-model="lieuFinChoix" class="resaModalEleves">
                <option>Lieu de retour</option>
                <template v-for="l in lieuxFin" :key="l.id">
                  <template v-if="l.choixEleve == true">
                    <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
                  </template>
                  
                </template>
              </select>
            </template>

          </template>

          <template v-else>
            <h4>L'élève sélectionné n'a pas choisi ce moniteur pour ses cours de conduite, il doit mondifier son choix pour inclure ce moniteur</h4>
          </template>



        </template>

        <template v-else>
          <h4>L'élève sélectionné doit choisir ses moniteurs et lieux de rendez-vous avant de pouvoir réserver un cours de conduite</h4>
        </template>

      </template>
      <br> <br> <br>
      
      <template v-if="lieuFinChoix != null">
        <template v-if="idEleveChoixResa != null">
          <template v-if="loadResaModalBtn == false">
            <button class="btn btn-primary btn-sm" @click="setApointment">
              VALIDER
            </button>
          </template>
          <template v-else>
            <button @click="setApointment()" class="btn btn-primary btn-sm" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
          </template>

        </template>
      </template>
    </Dialog>

    <Dialog
      v-model:visible="resaPopupEleve"
      modal
      :header="'Réservation : ' + resaHeader"
      :style="{ width: '60rem' }"
    >
      

      <select
        name="debLieu"
        id="debLieu"
        class="selectResaEleve"
        @click="getLieuFin"
        v-model="lieuDebChoix"
      >
        <option>Lieu de début</option>
        <template v-for="l in lieuxDeb" :key="l.id">
          <template v-if="popupMotif == true">
            <template v-if="lieuPrevAp[0] == ' ' + l.nom">
              <option style="color: blue" :value="l.id" :title="l.fulladresse">
                {{ l.nom }}
              </option>
            </template>
            <template v-else>
              <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
            </template>
          </template>
          <template v-else>
            <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
          </template>
        </template>
      </select>
      <template v-if="lieuxFin != null">
        <select name="finLieu" id="finLieu" class="selectResaEleve" v-model="lieuFinChoix">
          <option>Lieu de retour</option>
          <template v-for="l in lieuxFin" :key="l.id">
            <template v-if="popupMotif == true">
              <template v-if="lieuPrevAp[1] == l.nom">
                <option
                  style="color: blue"
                  :value="l.id"
                  :title="l.fulladresse"
                >
                  {{ l.nom }}
                </option>
              </template>
              <template v-else>
                <option :value="l.id" :title="l.fulladresse">
                  {{ l.nom }}
                </option>
              </template>
            </template>
            <template v-else>
              <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
            </template>
          </template>
        </select>
      </template>
      <br /><br />
      <template v-if="lieuFinChoix != null">
        <template v-if="loadResaModalBtn == false">
          <button
            class="btn btn-primary btn-sm"
            @click="setApointment"
            v-if="popupMotif == false"
          >
            VALIDER
          </button>
        </template>
        <template v-else>
          <button @click="setApointment()" class="btn btn-primary btn-sm" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
        </template>
        <template v-if="loadResaModalBtn == false">
          <button
            class="btn btn-primary btn-sm"
            @click="putApointment"
            v-if="popupMotif == true"
          >
            MODIFIER
          </button>
        </template>
        <template v-else>
          <button @click="putApointment()" v-if="popupMotif == true" class="btn btn-primary btn-sm" disabled><span><i class="fa fa-spinner fa-spin"></i>MODIFIER</span></button>
        </template>

      </template>
    </Dialog>

    <Dialog
      v-model:visible="popupMotif"
      modal
      header="Motif d'annulation du cours"
      :style="{ width: '50rem' }"
    >
      <select name="typeMotf" id="typeMotf" v-model="motif.leTypeMotif" class="selectResaEleve">
        <option value="null">Saisir le type de motif</option>
        <template v-for="t in typeMotif" :key="t.id">
          <option :value="t">{{ t.label }}</option>
        </template>
      </select>
      <input
        type="text"
        placeholder="Saisir un commentaire"
        id="motifText"
        v-model="motif.com"
      />
      <br /><br />
      <template v-if="motif.leTypeMotif != null">
        <template v-if="motif.com != ''">
          <button class="btn btn-primary btn-sm btnValMotif" @click="setMotif" v-if="loadCancelResa == false">
            VALIDER
          </button>

          <button class="btn btn-primary btn-sm btnValMotif" @click="setMotif" v-if="loadCancelResa == true" disabled>
            <span><i class="fa fa-spinner fa-spin"></i>VALIDER</span>
          </button>

        </template>
      </template>
      <button class="btn btn-info btn-sm" @click="showEditModal">
        MODIFIER
      </button>
    </Dialog>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import MonoModal from "@/components/agence/planning/modals/MonoModal.vue";
import moment from "moment";

export default {
  props: ["eleves", "moniteurs", "getEleves", "getMoniteurs"],
  components: {
    FullCalendar,
    MonoModal,
  },

  data() {
    return {
      loadResaModalBtn : false,
      resaPopupEleve: false,
      motif: {
        leTypeMotif: null,
        com: "",
      },
      firstCours: false,
      boiteResa: null,
      firstCoursFin: [],
      popupMotif: false,
      typeMotif: [],
      showEleveDispo: true,
      eleveDispos: [],
      idEleveChoixResa: null,
      lieuDebChoix: null,
      lieuFinChoix: null,
      lieuxDeb: [],
      lieuxFin: null,
      prixHeure: 0,
      monoChoixResa: null,
      resaModal: false,
      hideSundayBtn : false,
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin, resourceTimeGridPlugin],
        initialView: "resourceTimeGridWeek",
        themeSystem: "bootstrap",
        selectable: true,
        nowIndicator: true,
        navLinks: true,
        firstDay: 1,
        allDaySlot: true,
        slotMinTime: "07:00:00",
        slotMaxTime: "20:00:00",
        slotDuration: "00:15:00",
        height: "auto",
        datesAboveResources: true,
        locale: "fr",
        timeZone: "local",
        editable: true,
        eventResizableFromStart: true,
        customButtons:{
          hideSunday: {
            text: 'Dimanche',
            click: (e) => this.hideSundayMethod(e),
            background: "red"
          }
        },
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "resourceTimeGridWeek,resourceTimeGridDay,hideSunday",
        },

        buttonText: {
          today: "Aujourd'hui",
          resourceTimeGridDay: "Jour",
          resourceTimeGridWeek: "Semaine",
          prev: "<",
          next: ">",
        },
        resources: [],
        resourceOrder: "title",
        selectAllow: this.selectAllow,
        select: this.selectEvent,
        eventClick: this.deleteEvent,
        eventDidMount: this.eventhover,
        events: [],
        hiddenDays: []
      },
      moniteurchoix: [],
      moniteursList: [],
      loadCalendar: 0,
      planningResaE: null,
      resaHeader: "",
      lieuPrevAp: [],
      eleveChoixResa : null,
      eleveChoixResaMonoSelect : false,
      loadCancelResa : false,
    };
  },

  mounted() {
    //this.getFonctionnalite();
    this.putOnPlanningApointment();
    this.choixMonoSaves();
  },

  methods: {

    hideSundayMethod(e){
      if(this.hideSundayBtn){
        this.hideSundayBtn = false
        e.target.style.background = "#2196f3";
        this.calendarOptions.hiddenDays = [];
      }else{
        this.hideSundayBtn = true
        this.calendarOptions.hiddenDays = [0];
        e.target.style.background = "black";
      }
    },


    getLieuEleve(eleve){
      this.eleveChoixResa = eleve;
      if(eleve.choixDispo != 'null'){
        this.lieuxDeb.forEach(l => {
          eleve.choixDispo[0].choix.forEach(c => {
            if(c.id == this.monoChoixResa){
              this.eleveChoixResaMonoSelect = true;
              if(c.lieux != undefined){
                c.lieux.forEach(li => {
                  if(l.id == li){
                    l.choixEleve = true;
                  }
                })
              }
            }
          })
        })
      }

    },

    eventhover(info) {
      info.el.title = info.event.title;
    },

    getLesMoniteurs() {
      this.$emit("getMoniteurs");
    },

    choixMonoSaves() {
      window.api
        .get("/agence/choix/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          if (response.data.choix.length != 0) {
            this.$store.commit("setChoixSaveMono", response.data.choix[0]);
            this.choixSaveMono = response.data.choix[0];
            this.calendarOptions.resources = this.choixSaveMono.choix;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    diff_hours(dt2, dt1) {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(Math.round(diff));
    },

    setMotif() {
      this.loadCancelResa = true;
      window.api
        .post(
          "/cancel/apointment/" +
            this.motif.leTypeMotif.id +
            "/" +
            this.planningResaE["event"]["_def"]["publicId"] +
            "/" +
            this.$store.state.user.id,
          {
            com: this.motif.com,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.loadCancelResa = false;
          this.$emit("getEleves");
          this.popupMotif = false;

          if (response.data.apointment.typeMotif == "\tMono Absent") {
            response.data.apointment.typeMotif =
              response.data.apointment.typeMotif = "Mono Absent";
          }

          if (response.data.apointment.typeMotif == "Mono Absent") {
            let find = null;

            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });

            find.title = find.title + " - " + response.data.apointment.motif;
            find.motif = response.data.apointment.motif;
            find.color = "maroon";
            filter.push(find);

            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Incident") {
            let find = null;
            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });

            find.title = find.title + " - " + response.data.apointment.motif;
            find.motif = response.data.apointment.motif;
            find.color = "yellow";
            (find.textColor = "black"), filter.push(find);

            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Autre") {
            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });
            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Eleve Absent") {
            let hDiff = this.diff_hours(
              new Date(response.data.apointment.start.date),
              new Date(response.data.apointment.cancelDate.date)
            );
            let find = null;

            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });
            if (hDiff < 48) {
              find.title = find.title + " - " + response.data.apointment.motif;
              find.motif = response.data.apointment.motif;
              find.color = "red";
              filter.push(find);
              this.calendarOptions.events = filter;
            } else {
              this.calendarOptions.events = filter;
            }
          }

          this.leTypeMotif = null;
          this.com = "";

          window.api
            .post(
              "/sendMail",
              {
                type: "rdv annule",
                id: response.data.apointment.id,
                email: response.data.apointment.eleve.email,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });

          if (response.data.apointment.typeMotif != "Eleve Absent" && response.data.apointment.typeMotif != "Autre" && response.data.apointment.typeMotif != "Incident") {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    putOnPlanningApointment() {
      this.moniteurs.forEach((e) => {
        e.apointments.forEach((r) => {

          if(r.lieuFin.nom == "Cer impérativement"){
            let fin = r.lieuFin.nom.substring(3);
            r.lieuFin.nom = this.$store.state.user.agence.nom + fin;
          }else if(r.lieuFin.nom == "CER ou lieu début selon les besoins de l'agence"){
            let fin = r.lieuFin.nom.substring(18);
            r.lieuFin.nom = this.$store.state.user.agence.nom + " ou " + r.lieuDeb.nom + " " + fin;
          }

          if (r.motif == "null") {
            if (r.done) {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "teal",
                type: "resa",
                done: true,
                editable: false,
                motif: false,
                eleveId : r.eleve.id,
                resourceId: r.moniteur.idMoni,
              });
            } else {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "green",
                type: "resa",
                done: false,
                editable: false,
                motif: false,
                eleveId : r.eleve.id,
                resourceId: r.moniteur.idMoni,
              });
            }
          } else {
            if (r.typeMotif == "Eleve Absent") {
              let hDiff = this.diff_hours(
                new Date(r.start.date),
                new Date(r.cancelRdv.date)
              );

              if (hDiff <= 48) {
                this.calendarOptions.events.push({
                  id: r.id,
                  title:
                    r.eleve.fullname +
                    "  deb : " +
                    r.lieuDeb.nom +
                    "/ fin :" +
                    r.lieuFin.nom,
                  start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                  color: "red",
                  type: "resa",
                  done: false,
                  editable: false,
                  motif: false,
                  eleveId : r.eleve.id,
                  resourceId: r.moniteur.idMoni,
                });
              }
            } else if (r.typeMotif == "Mono Absent") {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "maroon",
                type: "resa",
                done: false,
                editable: false,
                eleveId : r.eleve.id,
                motif: false,
                resourceId: r.moniteur.idMoni,
              });
            } else if (r.typeMotif == "Incident") {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "yellow",
                type: "resa",
                done: false,
                textColor: "black",
                editable: false,
                eleveId : r.eleve.id,
                motif: false,
                resourceId: r.moniteur.idMoni,
              });
            }
          }
        });

        e.indispo.forEach((i) => {
          this.calendarOptions.events.push({
            id: i.id,
            title: "INDISPO",
            start: moment(i.start.date).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(i.end.date).format("YYYY-MM-DDTHH:mm:ss"),
            color: "grey",
            type: "indispo",
            motif: false,
            resourceId: i.moniteur.idMoni,
          });
        });
      });
    },

    getAllEleves(e) {
      if (e.target.checked) {
        this.showEleveDispo = false;
      } else {
        this.showEleveDispo = true;
      }
    },

    getLieuFin() {
      if (this.lieuDebChoix != null) {
        if (this.firstCours) {
          
          this.firstCoursFin.forEach(l => {
            this.eleveChoixResa.choixDispo[0].choix.forEach(c => {
              if(c.id == this.monoChoixResa){
                if(c.lieux != undefined){
                  c.lieux.forEach(li => {
                    if(l.id == li){
                      l.choixEleve = true;
                    }
                  })
                }
              }
            })
          })
          let lDeb = this.lieuxDeb.find(l => l.id == this.lieuDebChoix);

          let lxfin = [];
          this.lieuxFin = this.firstCoursFin;
          this.lieuxFin.forEach(l => {
            if(lDeb.groupe == 3){
              if(l.groupe == 1 || l.groupe == 2 || l.groupe == 3){
                lxfin.push(l);
              }
            }else{
              if(l.groupe == 1 || l.groupe == 2){
                lxfin.push(l);
              }
            }
          })

          this.lieuxFin = lxfin;

        } else {
          window.api
            .post(
              "get/lieux/" + this.monoChoixResa + "/resa/" + this.lieuDebChoix, 
              {
                start: moment(this.planningResaE.startStr).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(this.planningResaE.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              response.data.fin.forEach(l => {
                this.eleveChoixResa.choixDispo[0].choix.forEach(c => {
                  if(c.id == this.monoChoixResa){
                    if(c.lieux != undefined){
                      c.lieux.forEach(li => {
                        if(l.id == li){
                          l.choixEleve = true;
                        }
                      })
                    }
                  }
                })
              })

              this.lieuxFin = response.data.fin;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    displayDispo(eleve) {
      eleve.dispos.forEach((d) => {
        this.calendarOptions.events.push({
          id: d.id,
          title: "Dispo : " + d.lieu,
          start: moment(d.start.date).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(d.end.date).format("YYYY-MM-DDTHH:mm:ss"),
          color: "blue",
          type: "dispo",
        });
      });
    },

    changeDisplayApointment(display) {
      let dispos = [];
      let apointments = [];
      let indispos = [];

      this.calendarOptions.events.forEach((di) => {
        if (di.type == "dispo") {
          dispos.push(di);
        }

        if (di.type == "resa") {
          apointments.push(di);
        }

        if (di.type == "indispo") {
          indispos.push(di);
        }
      });

      if (display) {
        this.calendarOptions.events = [];
        dispos.forEach((d) => {
          this.calendarOptions.events.push({
            id: d.id,
            title: d.title,
            start: d.start,
            end: d.end,
            color: d.color,
            type: d.type,
            display: "background",
          });
        });

        apointments.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            done: r.done,
            editable: r.editable,
            motif: r.motif,
            resourceId: r.resourceId,
          });
        });

        indispos.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            resourceId: r.resourceId,
            motif: false,
          });
        });
      } else {
        this.calendarOptions.events = [];
        indispos.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            resourceId: r.resourceId,
            motif: false,
          });
        });

        dispos.forEach((d) => {
          this.calendarOptions.events.push({
            id: d.id,
            title: d.title,
            start: d.start,
            end: d.end,
            color: "blue",
            type: d.type,
          });
        });

        apointments.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            done: r.done,
            editable: r.editable,
            motif: r.motif,
            resourceId: r.resourceId,
            display: "background",
          });
        });
      }
    },

    getFonctionnalite() {
      window.api
        .get("/fonctionnalites/4", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.prixHeure = response.data.montant;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteEvent(e) {
      this.planningResaE = e;

      if (e["event"]["_def"]["extendedProps"]["type"] == "indispo") {
        window.api
          .delete("/indisponibilites/" + e["event"]["_def"]["publicId"], {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            let events = [];

            this.calendarOptions.events.forEach((i) => {
              if (i.id != e["event"]["_def"]["publicId"]) {
                events.push(i);
              }
            });

            this.calendarOptions.events = events;
          })
          .catch((error) => {
            console.log("ERRR::", error.response.data);
          });
      } else if (e["event"]["_def"]["extendedProps"]["type"] == "dispo") {
        window.api
          .delete("/disponibilites/" + e["event"]["_def"]["publicId"], {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            let events = this.calendarOptions.events.filter((i) => {
              i.id != e["_def"]["publicId"];
            });

            this.calendarOptions.events = events;
          })
          .catch((error) => {
            console.log("ERRR::", error.response.data);
          });
      } else if (
        e["event"]["_def"]["extendedProps"]["type"] == "resa" &&
        e["event"]["_def"]["extendedProps"]["motif"] == false &&
        e["event"]["_def"]["extendedProps"]["done"] == false
      ) {
        window.api
          .get("/type_motifs", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            
            let eleve = this.eleves.find((el) => el.id == e["event"]["_def"]["extendedProps"]["eleveId"])
            this.eleveChoixResa = eleve;
            this.typeMotif = response.data["hydra:member"];
            this.popupMotif = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    showEditModal() {
      let lieu = this.planningResaE["event"]["_def"]["title"].split("deb :");
      this.lieuPrevAp = lieu[1].split("/ fin :");
      this.monoChoixResa =
        this.planningResaE["event"]["_def"]["resourceIds"][0];
      window.api
        .post(
          "/get/lieux/" + this.monoChoixResa + "/resa",
          {
            start: this.planningResaE["event"]["_instance"]["range"]["start"],
            end: this.planningResaE["event"]["_instance"]["range"]["end"],
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((r) => {
          this.lieuxDeb = r.data.deb;
          this.firstCours = r.data.first;
          this.firstCoursFin = r.data.fin;
          this.boiteResa = r.data.boite;
          this.resaHeader =
            "Modification : " + this.planningResaE["event"]["_def"]["title"];
          this.resaPopupEleve = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelEleveChoix() {
      let filter = [];

      this.calendarOptions.events.forEach((a) => {
        if (a.type != "dispo") {
          filter.push(a);
        }
      });
      this.calendarOptions.events = filter;
    },

    putApointment() {
      this.loadResaModalBtn = true;
      window.api
        .put(
          "/apointment/" + this.planningResaE["event"]["_def"]["publicId"],
          {
            lieu: this.lieuDebChoix,
            lieuFin: this.lieuFinChoix,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          let filter = [];

          this.calendarOptions.events.forEach((a) => {
            if (a.id != response.data.apointment.id) {
              filter.push(a);
            }
          });
          this.calendarOptions.events = filter;
          this.calendarOptions.events.push({
            id: response.data.apointment.id,
            title:
              response.data.apointment.eleve.fullname +
              "  deb : " +
              response.data.apointment.lieuDeb.nom +
              "/ fin :" +
              response.data.apointment.lieuFin.nom,
            start: moment(response.data.apointment.start.date).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            end: moment(response.data.apointment.end.date).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            color: "green",
            type: "resa",
            eleveId : response.data.apointment.eleve.id,
            done: false,
            editable: false,
            motif: false,
            resourceId: response.data.apointment.moniteur.idMoni,
          });

          this.popupMotif = false;
          this.resaPopupEleve = false;
          this.loadResaModalBtn = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setApointment() {
      this.loadResaModalBtn = true;
      let eleve = null;
      if (this.$store.state.eleveSelectAgence != null) {
        eleve = this.$store.state.eleveSelectAgence.id;
      } else {
        eleve = this.idEleveChoixResa;
      }

      window.api
        .post(
          "/set/apointment/" +
            eleve +
            "/" +
            this.monoChoixResa +
            "/" +
            this.lieuDebChoix +
            "/" +
            this.lieuFinChoix,
          {
            start: moment(this.planningResaE.startStr).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            end: moment(this.planningResaE.endStr).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Solde insufisant") {
            alert("Le solde de l'élève selectioné est insufisant");
            this.loadResaModalBtn = false;
          } else {
            this.calendarOptions.events.push({
              id: response.data.apointment,
              title:
                response.data.eleve +
                "  deb : " +
                response.data.deb +
                "/ fin :" +
                response.data.fin,
              start: moment(this.planningResaE.startStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              end: moment(this.planningResaE.endStr).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              color: "green",
              type: "resa",
              done: false,
              editable: false,
              motif: false,
              eleveId : eleve,
              resourceId: this.monoChoixResa,
            });
          }
          this.$emit("getEleves");
          if (this.$store.state.eleveSelectAgence != null) {
            this.resaPopupEleve = false;
          } else {
            this.resaModal = false;
          }
          this.lieuxDeb = [];
          this.lieuxFin = null;
          this.lieuFinChoix = null;
          this.loadResaModalBtn = false;
          this.idEleveChoixResa = null;

          window.api
            .post(
              "/sendMail",
              {
                type: "rdv ok",
                id: response.data.apointment,
                email: response.data.eleveMail,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectEvent(e) {
      this.planningResaE = e;

      this.monoChoixResa = e["resource"]["_resource"]["id"];

      if (this.$store.state.planningSelectChoice == "INDISPO") {
        this.setIndispo(e);
      } else if (this.$store.state.planningSelectChoice == "DISPO") {
        this.setDispo(e);
      } else if (
        this.$store.state.planningSelectChoice == "RESA" &&
        !e.allDay
      ) {
        let students = this.eleves;
        students.forEach((eleve) => {
          eleve.dispos.forEach((dispo) => {
            let start = new Date(dispo.start.date);
            let end = new Date(dispo.end.date);

            let hDiff = this.diff_hours(start, end);

            if (start <= e.start && end >= e.end) {
              if (hDiff >= 2) {
                eleve.dispo = "V";
              } else {
                eleve.dispo = "X";
              }

              if (this.monoChoixResa != 14) {
                this.eleveDispos.push(eleve);
              } else {
                if (eleve.simu) {
                  this.eleveDispos.push(eleve);
                }
              }
            }
          });
        });

        window.api
          .post(
            "/get/lieux/" + this.monoChoixResa + "/resa",
            {
              start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((r) => {
            window.api
              .get("/prix/conduite", {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.prixHeure = response.data;

                if (this.$store.state.eleveSelectAgence != null) {
                  this.eleveChoixResa = this.$store.state.eleveSelectAgence;
                  this.resaPopupEleve = true;
                  this.resaHeader =
                    moment(this.planningResaE.startStr).format(
                      "ddd DD/MM/YY - HH"
                    ) +
                    "h - " +
                    moment(this.planningResaE.endStr).format("HH") +
                    "h - " +
                    "BA =" +
                    this.prixHeure.prixBa +
                    "€" +
                    " BM =" +
                    this.prixHeure.prixBm +
                    "€" +
                    " Réservation pour : " +
                    this.$store.state.eleveSelectAgence.fullname;
                } else {
                  this.resaModal = true;
                  this.resaHeader =
                    moment(this.planningResaE.startStr).format(
                      "ddd DD/MM/YY - HH"
                    ) +
                    "h - " +
                    moment(this.planningResaE.endStr).format("HH") +
                    "h - " +
                    "BA =" +
                    this.prixHeure.prixBa +
                    "€" +
                    " BM =" +
                    this.prixHeure.prixBm +
                    "€";
                }
              })
              .catch((error) => {
                console.log(error);
              });

            this.lieuxDeb = r.data.deb;
            this.firstCours = r.data.first;
            this.firstCoursFin = r.data.fin;
            this.boiteResa = r.data.boite;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    setDispo(e) {
      window.api
        .post(
          "/disponibilites",
          {
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            eleve: "api/utilisateurs/" + this.$store.state.eleveSelectAgence.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$emit("getEleves");
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "Dispo" + response.data.lieu,
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "blue",
            type: "dispo",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setIndispo(e) {
      window.api
        .post(
          "/indisponibilites",
          {
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            moniteur: "api/moniteurs/" + e["resource"]["_resource"]["id"],
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "INDISPO",
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "grey",
            type: "indispo",
            motif: false,
            resourceId: e["resource"]["_resource"]["id"],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    moniteurChoicePlanning(moniteur, checked) {
      this.moniteursList = this.$store.state.moniteursPlanningAgence;
      if (checked) {
        this.moniteurchoix.push({
          title: moniteur.prenom,
          id: moniteur.idMono,
        });
        this.calendarOptions.resources = this.moniteurchoix;
        let moniteurFind = this.moniteursList.find(
          (mono) => mono.idMono == moniteur.idMono
        );
        moniteurFind.checked = true;
        let moniteursFilter = this.moniteursList.filter(
          (mono) => mono.idMono != moniteurFind.idMono
        );
        moniteursFilter.push(moniteurFind);
        this.moniteursList = moniteursFilter;
        this.$store.commit("setMoniteursPlanningAgence", this.moniteursList);
      } else {
        let filter = this.calendarOptions.resources.filter(
          (mono) => mono.id != moniteur.idMono
        );
        this.calendarOptions.resources = filter;
        this.moniteurchoix = filter;
        let moniteurFind = this.moniteursList.find(
          (mono) => mono.idMono == moniteur.idMono
        );
        moniteurFind.checked = false;
        let moniteursFilter = this.moniteursList.filter(
          (mono) => mono.idMono != moniteurFind.idMono
        );
        moniteursFilter.push(moniteurFind);
        this.moniteursList = moniteursFilter;
        this.$store.commit("setMoniteursPlanningAgence", this.moniteursList);
      }
    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },
  },
};
</script>

<style scoped>

input[type=text]#motifText{
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

select.resaModalEleves{
  width: 40%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 4em;
  background-color: #f1f1f1;
}

select.selectResaEleve{
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1em;
  background-color: #f1f1f1;
}

select.monoSelectResaEleves{

  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1em;
  background-color: #f1f1f1;
}

div.head {
  display: flex;
  justify-content: space-around;
}
.selectMono {
  background-color: rgba(19, 192, 19, 0.2);
}

button.btnValMotif {
  margin-right: 50%;
}


</style>
